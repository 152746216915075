import React, {ChangeEvent, FormEvent, Fragment} from 'react';
import './App.scss';
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    FormGroup,
    FormText,
    Input,
    Label,
    Progress,
    Row,
    Table
} from "reactstrap";
import Fa from "../Fa";
import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import classNames from 'classnames';
import {round} from 'lodash';
import Swal from 'sweetalert2';

import 'react-bootstrap-typeahead/css/Typeahead.css';


interface SearchForm {
    query: string;
    katakana: string;
    algorithm: string;
    wildcard: string;
    similarity: string;
    transliterations: string;
    divisibility: string;
    pharmaInUse: string;
    ownerId: number;
    ownerName: string;
    status: string[];
    limit: string;
    showIRMarks: string;
    onlyApplications: string;
    katakanaSearch: string;
    englishPhoneticSearch: string;
    groups: SearchGroup[];
}

interface SearchGroup {
    niceClass: string;
    crossClass: string;
    similarityCode: string;
    similarityCodeMatchAll: string;
    niceClassBasedSimilarityCodeSearch: boolean;
}

interface Trademark {
    id: number;
    name: string;
    app_num: string;
    reg_num: string;
    int_reg_num: string;
    classification: string[];
    similarity: number;
    divisible: boolean;
    relevant_similarity: number;
    divisible_similarity: number;
    transliterations: Transliteration[];
    phonetics: Phonetic[];
    pharma_in_use: boolean;
    status: string;
    owner_name: string;
    owner_name_eng: string;
}

interface Transliteration {
    id: number;
    latin: string;
    katakana: string;
    similarity: number;
}

interface Phonetic {
    id: number;
    latin: string;
    similarity: number;
}

interface TrademarkOwner {
    id: number;
    name: string;
    nameInEnglish: string;
}

interface SearchStatus {
    status: string;
    duration: number;
    totalHits: number;
    revealedHits: number;
    processedHits: number;
    errorMessage: string;
    debug: any;
}

interface State {
    form: SearchForm;
    searching: boolean;
    status: SearchStatus | null;
    result: Trademark[];
    loadingOwners: boolean;
    loadingResult: boolean;
    owners: TrademarkOwner[];
}

const API_URL = process.env.REACT_APP_API_URL + '/';
const API_KEY = process.env.REACT_APP_API_KEY;

const LEVEL_DANGER = 90;
const LEVEL_WARNING = 80;

const api = axios.create({
    baseURL: API_URL,
    headers: {
        Authorization: 'Bearer ' + API_KEY,
    }
});

class App extends React.Component<any, State> {


    state: State = {
        form: {
            query: '',
            katakana: '',
            algorithm: '3',
            wildcard: '1',
            similarity: '70',
            divisibility: '0',
            pharmaInUse: '0',
            transliterations: '0',
            status: ['pending', 'registered'],
            limit: '350',
            ownerId: 0,
            ownerName: '',
            showIRMarks: '1',
            onlyApplications: '0',
            katakanaSearch: '1',
            englishPhoneticSearch: '0',
            groups: [{
                niceClass: '',
                crossClass: '0',
                similarityCode: '',
                similarityCodeMatchAll: '0',
                niceClassBasedSimilarityCodeSearch: false,
            }],
        },
        searching: false,
        loadingOwners: false,
        loadingResult: false,
        owners: [],
        status: null,
        result: [],
    };

    searchId: number = 0;

    debugDivRef: React.RefObject<HTMLDivElement>;

    constructor(props: any) {
        super(props);

        this.debugDivRef = React.createRef();
    }

    componentDidMount(): void {
        this.loadFormStore();
    }

    render(): React.ReactNode {
        return (
          <Container>
              <form onSubmit={e => this.onFormSubmit(e)}>
                  <Card className="my-4">
                      <CardBody>
                          <Row>
                              <Col xs={9}>
                                  <FormGroup>
                                      <Label>Search keyword</Label>
                                      <Input
                                        type={'text'}
                                        name={'query'}
                                        value={this.state.form.query}
                                        disabled={this.state.searching}
                                        onChange={e => this.onChangeInput(e)}
                                      />
                                      <FormText color={'muted'}>
                                          Katakana or latin characters without spaces or any special characters.
                                      </FormText>
                                  </FormGroup>
                              </Col>
                              <Col xs={3}>
                                  <FormGroup>
                                      <Label>Algorithm</Label>
                                      <Input
                                        type={'select'}
                                        name={'algorithm'}
                                        value={this.state.form.algorithm}
                                        disabled={this.state.searching}
                                        onChange={e => this.onChangeInput(e)}>
                                          <option value={'identical'}>Identical</option>
                                          <option value={'similar'}>Similar</option>
                                      </Input>
                                  </FormGroup>
                              </Col>
                          </Row>
                          <FormGroup>
                              <Label>Katakana</Label>
                              <Input
                                type={'text'}
                                name={'katakana'}
                                value={this.state.form.katakana}
                                disabled={this.state.searching}
                                onChange={e => this.onChangeInput(e)}
                              />
                              <FormText color={'muted'}>
                                  You can extend the search by adding an additional katakana.
                                  E.g. when you search for a latin name, but you want to add specific katakana too.
                                  If you want to add more than one terms, separate them by the comma (,) character.
                                  This field does not work without setting the search keyword, because this only extends
                                  that, not replaces.
                              </FormText>
                          </FormGroup>
                          {/*<FormGroup>
                              <Label>Owner name</Label>
                              <AsyncTypeahead
                                id={'owner-search'}
                                isLoading={this.state.loadingOwners}
                                onSearch={name => this.loadOwners(name)}
                                onInputChange={v => this.setOwnerName(v)}
                                onChange={v => this.setOwner(v[0])}
                                labelKey={owner => {
                                    if (owner.name && owner.nameInEnglish) {
                                        if (owner.name === owner.nameInEnglish) {
                                            return owner.name;
                                        }
                                        return owner.name + ' / ' + owner.nameInEnglish;
                                    }
                                    return owner.name || owner.nameInEnglish;
                                }}
                                disabled={this.state.searching}
                                filterBy={() => true}
                                useCache={false}
                                minLength={2}
                                options={this.state.owners}
                                delay={750}
                                selectHintOnEnter={true}
                              />
                              <FormText color={'muted'}>
                                  When you select owner from dropdown, only trademarks related to the selected owner
                                  will be displayed. But if you just type the owner's name, the search engine does full
                                  text search, what means that it can reveal all similar owners, not only the typed one,
                                  regardless it is exactly the same.
                              </FormText>
                          </FormGroup>*/}
                      </CardBody>
                  </Card>

                  <Card className="my-4">
                      <CardBody>
                          {this.state.form.groups.map((group, index) => (
                            <Fragment key={index}>
                                {index > 0 ? (
                                  <hr/>
                                ) : null}
                                <Row>
                                    <Col xs={9}>
                                        <FormGroup>
                                            <Label>Nice class</Label>
                                            <Input
                                              type={'text'}
                                              name={'niceClass'}
                                              value={group.niceClass}
                                              disabled={this.state.searching}
                                              onChange={e => this.onChangeGroupInput(index, e)}
                                              placeholder={'1, 3, 5-8, 9, 21-30'}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={3}>
                                        <FormGroup>
                                            <Label>Cross-class search</Label>
                                            <Input
                                              type={'select'}
                                              name={'crossClass'}
                                              value={group.crossClass}
                                              disabled={this.state.searching}
                                              onChange={e => this.onChangeGroupInput(index, e)}
                                            >
                                                <option value={'1'}>Yes</option>
                                                <option value={'0'}>No</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={9}>
                                        <FormGroup>
                                            <Label>Similarity code</Label>
                                            <Input
                                              type={'text'}
                                              name={'similarityCode'}
                                              value={group.similarityCode}
                                              disabled={this.state.searching}
                                              onChange={e => this.onChangeGroupInput(index, e)}
                                              placeholder={'01A01, 04A01, 28A01, 32F04'}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xs={3}>
                                        <FormGroup>
                                            <Label>Match all sim. codes</Label>
                                            <Input
                                              type={'select'}
                                              name={'similarityCodeMatchAll'}
                                              value={group.similarityCodeMatchAll}
                                              disabled={this.state.searching}
                                              onChange={e => this.onChangeGroupInput(index, e)}
                                            >
                                                <option value={'1'}>Yes (AND)</option>
                                                <option value={'0'}>No (OR)</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          id="niceClassBasedSimilarityCodeSearch"
                                          name={'niceClassBasedSimilarityCodeSearch'}
                                          className="custom-control-input"
                                          defaultChecked={group.niceClassBasedSimilarityCodeSearch}
                                          onChange={e => this.onChangeGroupCheckbox(index, e)}
                                        />
                                        <label className="custom-control-label"
                                               htmlFor="niceClassBasedSimilarityCodeSearch">
                                            Nice class based similarity code search
                                        </label>
                                    </div>
                                </FormGroup>

                                {this.state.form.groups.length > 1 ? (
                                  <div className="text-right">
                                      <Button color={'link'} onClick={() => this.removeGroup(index)}>
                                          Remove group
                                      </Button>
                                  </div>
                                ) : null}
                                {index === this.state.form.groups.length - 1 ? (
                                  <div>
                                      <Button color={'link'} onClick={() => this.addGroup()}>
                                          Add group
                                      </Button>
                                      <FormText color={'muted'}>
                                          You can do more complex search by adding groups.
                                          Within the groups there is an AND logic between the fields.
                                          So when you set nice class 5 and similarity code 01B01, then you will find
                                          trademarks that meet both conditions.
                                          But between the groups there is an OR logic, so you can search for trademarks
                                          in nice class 5 OR in similarity group 01B01 by setting the nice class in
                                          first group and the similarity code in the second group.
                                      </FormText>
                                      <FormText color={'muted'}>
                                          (Keyword OR Katakana) AND Owner AND ( (Nice class AND Sim. code) OR Group2 OR
                                          GroupN ) AND Status
                                      </FormText>
                                  </div>
                                ) : null}
                            </Fragment>
                          ))}
                      </CardBody>
                  </Card>

                  <Card className="my-4">
                      <CardBody>
                          <FormGroup className="mb-0">
                              <Label>Status</Label>
                              <Row>
                                  <Col xs={3}>
                                      <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            id="statusCheck1"
                                            className="custom-control-input"
                                            checked={this.isStatusChecked('pending')}
                                            onChange={e => this.onStatusChange('pending', e.target.checked)}
                                          />
                                          <label className="custom-control-label" htmlFor="statusCheck1">Pending</label>
                                      </div>
                                  </Col>
                                  <Col xs={3}>
                                      <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            id="statusCheck2"
                                            className="custom-control-input"
                                            checked={this.isStatusChecked('registered')}
                                            onChange={e => this.onStatusChange('registered', e.target.checked)}
                                          />
                                          <label className="custom-control-label"
                                                 htmlFor="statusCheck2">Registered</label>
                                      </div>
                                  </Col>
                                  <Col xs={4}>
                                      <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            id="statusCheck3"
                                            className="custom-control-input"
                                            checked={this.isStatusChecked('expired')}
                                            onChange={e => this.onStatusChange('expired', e.target.checked)}
                                          />
                                          <label className="custom-control-label" htmlFor="statusCheck3">Refused /
                                              Dismissed or Invalidated</label>
                                      </div>
                                  </Col>
                                  <Col xs={2}>
                                      <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            id="statusCheck4"
                                            className="custom-control-input"
                                            checked={this.isStatusChecked('other')}
                                            onChange={e => this.onStatusChange('other', e.target.checked)}
                                          />
                                          <label className="custom-control-label" htmlFor="statusCheck4">Other</label>
                                      </div>
                                  </Col>
                              </Row>
                          </FormGroup>
                      </CardBody>
                  </Card>

                  <Card className="my-4">
                      <CardBody>
                          <Row>
                              <Col xs={3}>
                                  <FormGroup>
                                      <Label>Wildcard</Label>
                                      <Input
                                        type={'select'}
                                        name={'wildcard'}
                                        value={this.state.form.wildcard}
                                        disabled={this.state.searching}
                                        onChange={e => this.onChangeInput(e)}
                                      >
                                          <option value={'1'}>Yes</option>
                                          <option value={'0'}>No</option>
                                      </Input>
                                  </FormGroup>
                              </Col>
                              <Col xs={3}>
                                  <FormGroup>
                                      <Label>Cut-off percentage</Label>
                                      <Input
                                        type={'number'}
                                        name={'similarity'}
                                        value={this.state.form.similarity}
                                        disabled={this.state.searching}
                                        onChange={e => this.onChangeInput(e)}
                                      />
                                  </FormGroup>
                              </Col>
                              <Col xs={3}>
                                  <FormGroup>
                                      <Label>Result hit limit (count)</Label>
                                      <Input
                                        type={'number'}
                                        name={'limit'}
                                        value={this.state.form.limit}
                                        disabled={this.state.searching}
                                        onChange={e => this.onChangeInput(e)}
                                      />
                                  </FormGroup>
                              </Col>
                              <Col xs={3}>
                                  <FormGroup>
                                      <Label>Show transliterations</Label>
                                      <Input
                                        type={'select'}
                                        name={'transliterations'}
                                        value={this.state.form.transliterations}
                                        disabled={this.state.searching}
                                        onChange={e => this.onChangeInput(e)}
                                      >
                                          <option value={'1'}>Yes</option>
                                          <option value={'0'}>No</option>
                                      </Input>
                                  </FormGroup>
                              </Col>
                          </Row>
                          <Row>
                              <Col xs={3}>
                                  <FormGroup>
                                      <Label>Divisibility (experimental)</Label>
                                      <Input
                                        type={'select'}
                                        name={'divisibility'}
                                        value={this.state.form.divisibility}
                                        disabled={this.state.searching}
                                        onChange={e => this.onChangeInput(e)}
                                      >
                                          <option value={'1'}>Yes</option>
                                          <option value={'0'}>No</option>
                                      </Input>
                                  </FormGroup>
                              </Col>
                              <Col xs={3}>
                                  <FormGroup>
                                      <Label>Pharma in use</Label>
                                      <Input
                                        type={'select'}
                                        name={'pharmaInUse'}
                                        value={this.state.form.pharmaInUse}
                                        disabled={this.state.searching}
                                        onChange={e => this.onChangeInput(e)}
                                      >
                                          <option value={'1'}>Yes</option>
                                          <option value={'0'}>No</option>
                                      </Input>
                                  </FormGroup>
                              </Col>
                              <Col xs={3}>
                                  <FormGroup>
                                      <Label>Show IR marks</Label>
                                      <Input
                                        type={'select'}
                                        name={'showIRMarks'}
                                        value={this.state.form.showIRMarks}
                                        disabled={this.state.searching}
                                        onChange={e => this.onChangeInput(e)}
                                      >
                                          <option value={'1'}>Yes</option>
                                          <option value={'0'}>No</option>
                                      </Input>
                                  </FormGroup>
                              </Col>
                              <Col xs={3}>
                                  <FormGroup>
                                      <Label>Show only applications</Label>
                                      <Input
                                        type={'select'}
                                        name={'onlyApplications'}
                                        value={this.state.form.onlyApplications}
                                        disabled={this.state.searching}
                                        onChange={e => this.onChangeInput(e)}
                                      >
                                          <option value={'1'}>Yes</option>
                                          <option value={'0'}>No</option>
                                      </Input>
                                  </FormGroup>
                              </Col>
                          </Row>
                          <Row>
                              <Col xs={3}>
                                  <FormGroup>
                                      <Label>Katakana search</Label>
                                      <Input
                                        type={'select'}
                                        name={'katakanaSearch'}
                                        value={this.state.form.katakanaSearch}
                                        disabled={this.state.searching}
                                        onChange={e => this.onChangeInput(e)}
                                      >
                                          <option value={'1'}>Enabled</option>
                                          <option value={'0'}>Disabled</option>
                                      </Input>
                                  </FormGroup>
                              </Col>
                              <Col xs={3}>
                                  <FormGroup>
                                      <Label>English phonetic search</Label>
                                      <Input
                                        type={'select'}
                                        name={'englishPhoneticSearch'}
                                        value={this.state.form.englishPhoneticSearch}
                                        disabled={this.state.searching}
                                        onChange={e => this.onChangeInput(e)}
                                      >
                                          <option value={'1'}>Enabled</option>
                                          <option value={'0'}>Disabled</option>
                                      </Input>
                                  </FormGroup>
                              </Col>
                          </Row>
                      </CardBody>
                  </Card>

                  <div className="mb-4">
                      <Button type={'submit'} color={'primary'} disabled={this.state.searching}>
                          {this.state.searching ? (
                            <Fa icon={'sync'} spin fixedWidth/>
                          ) : (
                            <Fa icon={'search'} fixedWidth/>
                          )}
                          {this.state.searching ? (
                            <span className="ml-2">Searching</span>
                          ) : (
                            <span className="ml-2">Search</span>
                          )}
                      </Button>

                      {this.isShowResetForm() ? (
                        <Button
                          type={'button'}
                          color={'link'}
                          disabled={this.state.searching}
                          onClick={() => this.onClickResetForm()}
                        >
                            Reset Form
                        </Button>
                      ) : false}
                  </div>
              </form>

              <Card className="mb-4">
                  <CardBody>
                      {this.state.searching ? (
                        <div className="mb-3">
                            <Progress
                              value={this.state.status?.processedHits || 0}
                              max={this.state.status?.totalHits || 100}
                            />
                        </div>
                      ) : false}
                      <Row className="mb-3">
                          <Col>
                              <div className="small text-uppercase">Total hits</div>
                              <div>
                                  {this.state.searching && this.state.status?.totalHits ? (
                                    <Fragment>
                                        {this.state.status.processedHits}
                                        <span className="mx-1">/</span>
                                    </Fragment>
                                  ) : false}
                                  {this.state.status?.totalHits || '-'}
                              </div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">Revealed hits</div>
                              <div>{this.state.status?.revealedHits || '-'}</div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">Displayed hits</div>
                              <div>{this.state.result.length || '-'}</div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">Total time</div>
                              <div>
                                  {this.state.status?.duration ? (
                                    <span>{this.getTimeText(this.state.status.duration)}</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                      </Row>
                      <Row className="mb-3">
                          <Col>
                              <div className="small text-uppercase">Search time</div>
                              <div>
                                  {this.state.status?.debug?.elasticsearch_time ? (
                                    <span>{this.getTimeText(this.state.status.debug.elasticsearch_time)}</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">Evaluation time</div>
                              <div>
                                  {this.state.status?.debug?.evaluation_time ? (
                                    <span>{this.getTimeText(this.state.status.debug.evaluation_time)}</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">Katakana evaluation</div>
                              <div>
                                  {this.state.status?.debug?.katakana_evaluation_count ? (
                                    <Fragment>
                                        {this.state.status.debug.katakana_evaluation_item}
                                        <span className="mx-1">/</span>
                                        {this.state.status.debug.katakana_evaluation_count}
                                        <span className="mx-1">/</span>
                                        {this.getTimeText(this.state.status.debug.katakana_evaluation_time)}
                                    </Fragment>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">Phonetic evaluation</div>
                              <div>
                                  {this.state.status?.debug?.phonetic_evaluation_count ? (
                                    <Fragment>
                                        {this.state.status.debug.phonetic_evaluation_item}
                                        <span className="mx-1">/</span>
                                        {this.state.status.debug.phonetic_evaluation_count}
                                        <span className="mx-1">/</span>
                                        {this.getTimeText(this.state.status.debug.phonetic_evaluation_time)}
                                    </Fragment>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                      </Row>
                      <Row className="mb-3">
                          <Col>
                              <div className="small text-uppercase">Pharma in use</div>
                              <div>
                                  {this.state.status?.debug?.pharma_in_use_time ? (
                                    <Fragment>
                                        {this.state.status.debug.pharma_in_use_item}
                                        <span className="mx-1">/</span>
                                        {this.getTimeText(this.state.status.debug.pharma_in_use_time)}
                                    </Fragment>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">Thread dispatch</div>
                              <div>
                                  {this.state.status?.debug?.thread_dispatch_time ? (
                                    <span>{this.getTimeText(this.state.status.debug.thread_dispatch_time)}</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">Thread process</div>
                              <div>
                                  {this.state.status?.debug?.thread_process_time ? (
                                    <span>{this.getTimeText(this.state.status.debug.thread_process_time)}</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">Thread wait</div>
                              <div>
                                  {this.state.status?.debug?.thread_wait_time ? (
                                    <span>{this.getTimeText(this.state.status.debug.thread_wait_time)}</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                      </Row>
                      <Row className="mb-3">
                          <Col>
                              <div className="small text-uppercase">Result sorting</div>
                              <div>
                                  {this.state.status?.debug?.result_sorting_time ? (
                                    <span>{this.getTimeText(this.state.status.debug.result_sorting_time)}</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">Database time</div>
                              <div>
                                  {this.state.status?.debug?.result_array_time ? (
                                    <span>{this.getTimeText(this.state.status.debug.result_array_time)}</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">API Memory usage</div>
                              <div>
                                  {this.state.status?.debug?.api_memory_usage ? (
                                    <span>{round(this.state.status.debug.api_memory_usage / 1024 / 1024)} MB</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">Search Memory usage</div>
                              <div>
                                  {this.state.status?.debug?.search_memory_usage ? (
                                    <span>{round(this.state.status.debug.search_memory_usage / 1024 / 1024)} MB</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                      </Row>
                      <Row>
                          <Col>
                              <div className="small text-uppercase">RED level</div>
                              <div>
                                  {this.state.result.length ? (
                                    <span>{this.getLevelCount(LEVEL_DANGER, 100)}</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">YELLOW level</div>
                              <div>
                                  {this.state.result.length ? (
                                    <span>{this.getLevelCount(LEVEL_WARNING, LEVEL_DANGER)}</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">GREEN level</div>
                              <div>
                                  {this.state.result.length ? (
                                    <span>{this.getLevelCount(0, LEVEL_WARNING)}</span>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                          <Col>
                              <div className="small text-uppercase">Search query</div>
                              <div>
                                  {this.state.status?.debug?.keywords ? (
                                    <Fragment>
                                        {this.state.status.debug.keywords.map((s: any) => (
                                          <div key={`keyword-${s.katakana}-${s.latin}`}>
                                              <span className="mr-2">{s.katakana}</span>
                                              <span className="small text-muted">{s.latin}</span>
                                          </div>
                                        ))}
                                    </Fragment>
                                  ) : (
                                    <span>-</span>
                                  )}
                              </div>
                          </Col>
                      </Row>
                  </CardBody>
              </Card>

              {this.state.loadingResult ? (
                <div className="text-center mb-5">
                    <h4>Loading search result...</h4>
                </div>
              ) : false}

              {this.state.result.length ? (
                <Card className="mb-4">
                    <Table hover={true} className="mb-0">
                        <thead>
                        <tr>
                            <th/>
                            <th/>
                            <th>App/Reg No.</th>
                            {this.state.form.transliterations === '1' ? (
                              <th>Name / transliterations</th>
                            ) : (
                              <th>Name</th>
                            )}
                            <th>Similarity</th>
                            <th>Nice class</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.result.map((trademark, i) => (
                          <Fragment key={'trademark-' + trademark.id}>
                              <tr
                                className={classNames('tr-trademark', this.getLevel(trademark), {
                                    'tr-gray': i % 2 === 0,
                                    'has-transliterations': trademark.transliterations
                                })} key={'tr' + trademark.id}>
                                  <td>{i + 1}.</td>
                                  <td>
                                      {trademark.similarity > LEVEL_DANGER ? (
                                        <Fa icon={'exclamation-circle'} className="text-danger"/>
                                      ) : trademark.similarity > LEVEL_WARNING ? (
                                        <Fa icon={'exclamation-triangle'} className="text-warning"/>
                                      ) : false}
                                  </td>
                                  <td className="text-nowrap">
                                      {trademark.app_num ? (
                                        <div>AN: {trademark.app_num}</div>
                                      ) : false}
                                      {trademark.reg_num ? (
                                        <div>RN: {trademark.reg_num}</div>
                                      ) : false}
                                      {trademark.int_reg_num ? (
                                        <div>IRN: {trademark.int_reg_num}</div>
                                      ) : false}
                                  </td>
                                  <td>
                                      <div>
                                          <span>{trademark.name}</span>
                                          {trademark.pharma_in_use ? (
                                            <span className="ml-2">[PiU]</span>
                                          ) : false}
                                      </div>
                                      {trademark.owner_name || trademark.owner_name_eng ? (
                                        <div className="text-muted">
                                            {trademark.owner_name === trademark.owner_name_eng ? (
                                              <span>{trademark.owner_name}</span>
                                            ) : trademark.owner_name && trademark.owner_name_eng ? (
                                              <span>{trademark.owner_name} / {trademark.owner_name_eng}</span>
                                            ) : (
                                              <span>{trademark.owner_name || trademark.owner_name_eng}</span>
                                            )}
                                        </div>
                                      ) : false}
                                  </td>
                                  <td className="text-nowrap">
                                      {trademark.similarity ? (
                                        <span>{trademark.similarity}%</span>
                                      ) : false}
                                  </td>
                                  <td>
                                      {trademark.classification.map((c, i) => (
                                        <Fragment key={c}>
                                            {i && i % 6 === 0 ? <br/> : false}
                                            <span className="mr-1">{c}</span>
                                        </Fragment>
                                      ))}
                                  </td>
                                  <td>{trademark.status}</td>
                              </tr>
                              {this.state.form.transliterations === '1' && trademark.transliterations && trademark.transliterations.map((tr, j) => (
                                <tr className={classNames('tr-transliteration', this.getLevel(trademark), {
                                    'tr-gray': i % 2 === 0,
                                    'tr-last': j === trademark.transliterations.length - 1
                                })} key={'transliteration-' + trademark.id + '-' + tr.id}>
                                    <td className="py-1" colSpan={3}/>
                                    <td className="py-1">
                                        <span>{tr.katakana}</span>
                                        <span className="text-muted ml-3">{tr.latin}</span>
                                    </td>
                                    <td className="py-1 text-muted">
                                        {tr.similarity ? (
                                          <span>{tr.similarity}%</span>
                                        ) : trademark.similarity ? (
                                          <span>-</span>
                                        ) : false}
                                    </td>
                                    <td className="py-1" colSpan={2}/>
                                </tr>
                              ))}
                              {this.state.form.transliterations === '1' && trademark.phonetics && trademark.phonetics.map((p, j) => (
                                <tr className={classNames('tr-transliteration', this.getLevel(trademark), {
                                    'tr-gray': i % 2 === 0,
                                    'tr-last': j === trademark.phonetics.length - 1
                                })} key={'phonetic-' + trademark.id + '-' + p.id}>
                                    <td className="py-1" colSpan={3}/>
                                    <td className="py-1">
                                        <span>{p.latin}</span>
                                        <span className="text-muted ml-3">(phonetic)</span>
                                    </td>
                                    <td className="py-1 text-muted">
                                        {p.similarity ? (
                                          <span>{p.similarity}%</span>
                                        ) : trademark.similarity ? (
                                          <span>-</span>
                                        ) : false}
                                    </td>
                                    <td className="py-1" colSpan={2}/>
                                </tr>
                              ))}
                          </Fragment>
                        ))}
                        </tbody>
                    </Table>
                </Card>
              ) : false}
          </Container>
        );
    }

    onChangeInput(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
        const input = e.currentTarget;
        this.setState(({form}) => ({
            form: {...form, [input.name]: input.value}
        }));
    }

    onChangeGroupInput(index: number, e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
        const input = e.currentTarget;

        const groups = this.state.form.groups.map((group, i) => {
            return i === index ? {...group, [input.name]: input.value} : group;
        });

        this.setState(({form}) => ({form: {...form, groups}}));
    }

    onChangeGroupCheckbox(index: number, e: ChangeEvent<HTMLInputElement>): void {
        const input = e.currentTarget;

        const groups = this.state.form.groups.map((group, i) => {
            const key = input.name as keyof SearchGroup;

            return i === index ? {...group, [key]: !group[key]} : group;
        });

        this.setState(({form}) => ({form: {...form, groups}}));
    }

    private onFormSubmit(e: FormEvent): void {
        e.preventDefault();

        if (this.state.searching) {
            return;
        }

        this.setState({
            searching: true,
            status: null,
            result: [],
        });

        this.saveFormStore();

        const url = '/search';

        api.post<any>(url, this.state.form)
          .then(response => {
              this.searchId = response.data.id;
              setTimeout(() => this.checkSearchStatus(), 2500);
          })
          .catch((e: AxiosError) => {
              if (e.response) {
                  Swal.fire({title: 'Error', text: e.response.data.message});
              } else {
                  Swal.fire({title: e.name, text: e.message});
              }
              this.setState({searching: false});
          });
    }

    private checkSearchStatus(): void {

        const url = '/search/' + this.searchId + '/status';

        let errorCount = 0;

        api.get<SearchStatus>(url)
          .then(response => {
              this.setState({status: response.data});

              errorCount = 0;

              if (response.data.status === 'DONE') {
                  this.loadSearchResult();
                  this.setState({searching: false});
              } else if (response.data.status === 'FAILED') {
                  Swal.fire({title: 'Error', text: response.data.errorMessage});
                  this.setState({searching: false});
              } else {
                  setTimeout(() => this.checkSearchStatus(), 2500);
              }
          })
          .catch(e => {
              console.error(e);
              if (errorCount++ < 3) {
                  setTimeout(() => this.checkSearchStatus(), 2500);
              }
          });
    }

    private loadSearchResult(): void {

        const url = '/search/' + this.searchId + '/result';

        this.setState({loadingResult: true});

        const config: AxiosRequestConfig = {
            params: {
                transliterations: this.state.form.transliterations
            }
        };

        api.get<Trademark[]>(url, config)
          .then(response => {
              this.setState({result: response.data});
          })
          .catch((e: AxiosError) => {
              if (e.response) {
                  Swal.fire({title: 'Error', text: e.response.data.message});
              } else {
                  Swal.fire({title: e.name, text: e.message});
              }
          })
          .then(() => this.setState({loadingResult: false}));
    }

    private isShowResetForm(): boolean {
        // return this.state.form.query !== ''
        //   || this.state.form.niceClass !== ''
        //   || this.state.form.similarity !== '70';
        return false;
    }

    private onClickResetForm(): void {
        this.setState(({form}) => ({
            form: {
                ...form,
                query: '',
                niceClass: '',
                similarity: '70',
            }
        }), () => {
            this.saveFormStore();
        });
    }

    private getLevelCount(min: number, max: number): number {
        return this.state.result.filter(t => t.similarity > min && t.similarity <= max).length;
    }

    private getLevel(trademark: Trademark): string {

        if (trademark.similarity > LEVEL_DANGER) {
            return 'level-high';
        } else if (trademark.similarity > LEVEL_WARNING) {
            return 'level-medium';
        } else {
            return 'level-low';
        }
    }

    private loadFormStore(): void {
        this.setState(({form}) => ({
            form: {
                ...form,
                query: localStorage.getItem('search_form-query') || '',
                katakana: localStorage.getItem('search_form-katakana') || '',
                algorithm: localStorage.getItem('search_form-algorithm') || 'similar',
                wildcard: localStorage.getItem('search_form-wildcard') || '1',
                similarity: localStorage.getItem('search_form-similarity') || '70',
                divisibility: localStorage.getItem('search_form-divisibility') || '0',
                pharmaInUse: localStorage.getItem('search_form-pharmaInUse') || '0',
                transliterations: localStorage.getItem('search_form-transliterations') || '0',
                katakanaSearch: localStorage.getItem('search_form-katakanaSearch') || '1',
                englishPhoneticSearch: localStorage.getItem('search_form-englishPhoneticSearch') || '0',
            },
        }));

        const groups = localStorage.getItem('search_form-groups');
        if (groups) {
            this.setState(({form}) => ({
                form: {...form, groups: JSON.parse(groups)},
            }));
        }
    }

    private saveFormStore(): void {
        localStorage.setItem('search_form-query', this.state.form.query);
        localStorage.setItem('search_form-katakana', this.state.form.katakana);
        localStorage.setItem('search_form-algorithm', this.state.form.algorithm);
        localStorage.setItem('search_form-wildcard', this.state.form.wildcard);
        localStorage.setItem('search_form-similarity', this.state.form.similarity);
        localStorage.setItem('search_form-divisibility', this.state.form.divisibility);
        localStorage.setItem('search_form-pharmaInUse', this.state.form.pharmaInUse);
        localStorage.setItem('search_form-transliterations', this.state.form.transliterations);
        localStorage.setItem('search_form-katakanaSearch', this.state.form.katakanaSearch);
        localStorage.setItem('search_form-englishPhoneticSearch', this.state.form.englishPhoneticSearch);
        localStorage.setItem('search_form-groups', JSON.stringify(this.state.form.groups));
    }

    private isStatusChecked(value: string): boolean {
        return this.state.form.status.includes(value);
    }

    private onStatusChange(value: string, checked: boolean): void {
        this.setState(({form}) => {
            const status = Array.from(form.status);

            if (checked && !status.includes(value)) {
                status.push(value);
            }
            if (!checked && status.includes(value)) {
                status.splice(status.indexOf(value), 1);
            }

            return {form: {...form, status}};
        });
    }

    private setOwner(owner?: TrademarkOwner): void {
        this.setState(({form}) => ({
            form: {
                ...form,
                ownerId: owner ? owner.id : 0,
            }
        }))
    }

    private setOwnerName(name: string): void {
        this.setState(({form}) => ({
            form: {
                ...form,
                ownerName: name
            }
        }))
    }

    private loadOwners(name: string) {
        // this.setState({loadingOwners: true});
        //
        // const config: AxiosRequestConfig = {
        //     params: {
        //         name
        //     }
        // };
        //
        // axios.get<TrademarkOwner[]>(API_URL + 'owner-search', config)
        //   .then(response => {
        //       this.setState({owners: response.data});
        //   })
        //   .catch((error: AxiosError) => {
        //       if (error.response) {
        //           alert(error.response.data.message || error.response.statusText)
        //       } else {
        //           alert(error.message);
        //       }
        //   })
        //   .then(() => {
        //       this.setState({loadingOwners: false})
        //   });
    };

    private getTimeText(time: number): string {
        if (time > 1000) {
            return round(time / 1000, 1) + ' sec';
        }

        return round(time) + ' ms';
    }

    private addGroup(): void {
        const groups = this.state.form.groups;

        groups.push({
            niceClass: '',
            crossClass: '0',
            similarityCode: '',
            similarityCodeMatchAll: '0',
            niceClassBasedSimilarityCodeSearch: false,
        });

        this.setState(({form}) => ({form: {...form, groups}}));
    }

    private removeGroup(index: number): void {
        const groups = this.state.form.groups.filter((_, i) => i !== index);

        this.setState(({form}) => ({form: {...form, groups}}));
    }
}

export default App;
